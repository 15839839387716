<template>
  <div class="sweepstakes_wrap">
    <h1>试抽——北纳生物2021开工有礼</h1>
    <p class="tishi_th">温馨提示：您可以点击下图试抽，试抽结束后可点击下方按钮正式抽奖。试抽奖品不作为兑奖依据</p>
    <div class="sweepstakes_content">
      <div class="wrapper open-body">
        <div class="bgbody rotate"></div>
        <div class="open-has">
          <h3 class="title-close"></h3>
          <div class="mod-chest">
            <div class="chest-close show chest-onclose">
              <div class="gift"></div>
              <div class="tips tips_unpacking">
                <i class="arrow"></i>
              </div>
            </div>
            <div class="chest-open">
              <div class="mod-chest-cont open-cont">
                <div class="content">
                  <img
                    class="close-unpacking-img"
                    src="https://www.bncc.com/static/pc/img/unpacking/close-unpacking.png"
                    alt="北纳生物2021开工有礼"
                  />
                  <div class="gift">
                    <div class="icon">
                      <img
                        class="chest_zuan"
                        src="https://www.bncc.com/static/pc/img/unpacking/waveoven.png"
                      />
                    </div>
                  </div>
                  <div class="func">
                    <div class="chest-btn">
                      试抽获得<span class="user userText">100代金券</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="open-none" style="display: none">
          <h3>你来晚啦，下次早点吧！</h3>
          <div class="mod-chest">
            <div class="chest-open show"></div>
          </div>
          <div class="func">
            <button class="chest-btn">查看领取详情</button>
          </div>
        </div>
      </div>
      <div class="startImg">
        <router-link :to="{ name: 'StartJobReal' }"
          ><img
            src="https://www.bncc.com/static/images/beginStartImg.jpg"
            alt="开始抽奖"
        /></router-link>
      </div>
      <div class="sweepstakes_bottom">
        <div class="Sweepstakes_guize">
          <span class="Sweepstakes_guize_title" style="margin-top: 15px"
            >活动规则</span
          >
          <div class="Sweepstakes_guize_content">
            <p style="margin-top: 10px">1、本次活动对象仅限北纳生物客户；</p>
            <p>2、活动时间：2022年02月19日——2022年02月26日。</p>
            <p>
              3、活动期间，每人共有两次抽奖机会，伟业官网PC一次，关注微信公众号可以再抽一次
            </p>
            <p>4、特殊时期发件会延期，快递正常后会第一时间寄出，请耐心等待。</p>
            <p>注：本活动最终解释权归北纳生物所有</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted } from "vue";
import $ from "jquery";
export default defineComponent({
  name: "StartJob",
  setup() {
    const imgArr = [
      {
        img: require("../assets/img/start-job/pingguo.png"),
        width: "122px",
        top: "-67px",
        left: "-5px",
        text: "iphone12 Pro Max",
      },
      {
        img: require("../assets/img/start-job/kongtiao.png"),
        width: "30px",
        top: "-67px",
        left: "-5px",
        text: "美的(Midea)3匹客厅圆柱空调柜机",
      },
      {
        img: require("../assets/img/start-job/touyingyi.png"),
        width: "220px",
        top: "-49px",
        left: "24px",
        text: "小米投影仪",
      },
      {
        img: require("../assets/img/start-job/zixinche.png"),
        width: "170px",
        top: "-67px",
        left: "-5px",
        text: "麦瑞克家用动感单车",
      },
      {
        img: require("../assets/img/start-job/weibo.png"),
        width: "180px",
        top: "-78px",
        left: "0",
        text: "美的微波炉",
      },
      {
        img: "http://www.bncc.com/static/pc/img/unpacking/integral.png",
        width: "80px",
        top: "-49px",
        left: "24px",
        text: "积分18888",
      },
      {
        img: "http://www.bncc.com/static/pc/img/unpacking/integral.png",
        width: "80px",
        top: "-49px",
        left: "24px",
        text: "积分8888",
      },
      {
        img: require("../assets/img/start-job/guangpan.png"),
        width: "80px",
        top: "-49px",
        left: "24px",
        text: "北纳生物培训中心课程光盘",
      },
      {
        img: require("../assets/img/start-job/lipin.png"),
        width: "80px",
        top: "-49px",
        left: "24px",
        text: "伟业数据线",
      },
    ];
    let chestCloseTimer: number | undefined;
    onMounted(() => {
      nextTick(() => {
        $(".chest-onclose").click(function () {
          const that = $(".chest-onclose");
          const floorNum = Math.floor(Math.random() * 10);
          $(that).addClass("shakecopy");

          let imgUrl = {};
          for (let i = 0; i < imgArr.length; i++) {
            if (floorNum == i) {
              imgUrl = imgArr[i];
            }
          }
          $(".userText").text((imgUrl as any).text);
          $(".chest_zuan").css({
            width: (imgUrl as any).width,
            top: (imgUrl as any).top,
            left: (imgUrl as any).left,
          });
          $(".chest_zuan").attr("src", (imgUrl as any).img);
          $(".open-has").addClass("opened");
          setTimeout(function () {
            $(that).removeClass("show");
            $(".chest-open").addClass("show");
            setTimeout(function () {
              $(".chest-open").addClass("blur");
            }, 500);
          }, 700);
          // chestCloseTimer = setTimeout(function () {
          //   $(".chest-close").removeClass("shakecopy");
          //   $(".open-has").removeClass("opened");
          //   $(".chest-open").removeClass("show");
          //   $(".chest-close").addClass("show");
          //   $(".chest-open").removeClass("blur");
          // }, 4000);
        });

        $(".close-unpacking-img").click(function () {
          clearTimeout(chestCloseTimer);
          $(".chest-close").removeClass("shakecopy");
          $(".open-has").removeClass("opened");
          $(".chest-open").removeClass("show");
          $(".chest-close").addClass("show");
          $(".chest-open").removeClass("blur");
        });
      });
    });
  },
});
</script>

<style lang="scss" >
.sweepstakes_wrap {
  padding: 10px;
  font-size: 12px;
}

.sweepstakes_wrap h1 {
  text-align: center;
  line-height: 40px;
  color: #666666;
}

.sweepstakes_title {
  margin-top: 10px;
}

.sweepstakes_title textarea {
  width: 100%;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #808080;
}

.sweepstakes_title p,
.Sweepstakes_guize_title {
  font-size: 16.5px;
  font-weight: bold;
  position: relative;
  padding-left: 10px;
}

.sweepstakes_title p::after,
.Sweepstakes_guize_title::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
  width: 3px;
  height: 17.5px;
  background: #df0024;
}

.sweepstakes_huida {
  display: block;
  line-height: 15px;
  font-size: 15px;
  margin-top: 10px;
  color: rgb(102, 102, 102);
}

.sweepstakes_guan {
  display: block;
  margin-top: 10px;
  font-weight: 600;
  font-size: 16.5px;
  margin-bottom: 10px;
  line-height: 30px;
}

.sweepstakes_guan input {
  width: 40px;
  height: 20px;
  line-height: 20px;
  border: none;
  border-bottom: 1px solid;
  outline: none;
  text-align: center;
}

.unpacking_input {
  width: 60px !important;
}

.sweepstakes_title span {
  font-size: 12.5px;
  display: inline-block;
}

.sweepstakes_title span a {
  color: #0072bb;
}

.sweepstakes_btn {
  display: inline-block;
  width: 100%;
  height: 45px;
  background-color: #d64b4b;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 2.5px;
  line-height: 45px;
  text-align: center;
  color: #fff !important;
  font-size: 15px;
}

.close-unpacking-img{
  width: 18px;
  float: right;
}

.Sweepstakes_guize {
  margin-top: 20px;
  text-align: left;
  color: #666;
}

.Sweepstakes_guize_content {
  font-size: 15px;
  line-height: 28px;
}

.sweepstakes_content {
  text-align: center;
  position: relative;
}

.Sweepstakes_start {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -37.5px;
  margin-top: -37.5px;
  cursor: pointer;
  width: 75px;
}

.Sweepstakes_dibu {
  width: 100%;
}

.challengeWhellSelect {
  width: 80px;
  height: 25px;
  background: none;
  font-family: "Microsoft YaHei";
}

.challengeWhellTitle {
  text-align: center;
  font-size: 14px;
  line-height: 25px;
}

.Sweepstakes_bottom_content {
  line-height: 30px;
}

.Sweepstakes_bottom_content p a {
  text-decoration: underline;
  color: #2a2aca;
}
.tishi_th {
  text-align: center;
  color: #f4ad26;
  margin-top: 5px;
  font-size: 14px;
}
.startImg {
  text-align: center;
}

.startImg img {
  width: 150px;
}
.mod-chest-cont {
  background: url(http://www.bncc.com/static/pc/img/unpacking/open-bg-top.png)
      center top no-repeat,
    url(http://www.bncc.com/static/pc/img/unpacking/open-bg-bottom.png) center
      bottom no-repeat;
  background-size: 276px auto;
  width: 276px;
  margin: 27px auto 118px;
  position: relative;
}

.mod-chest-cont:before {
  width: 100%;
  position: absolute;
  top: 12px;
  bottom: 80px;
  left: 0px;
  content: "";
  background: #fff;
  border-left: #e59c00 1px solid;
  border-right: #e59c00 1px solid;
  box-sizing: border-box;
}

.mod-chest-cont .content {
  position: relative;
  z-index: 2;
  padding: 13px;
}

.mod-chest-cont h3 {
  color: #865500;
  font-size: 15px;
}

.mod-chest {
  position: relative;
  width: 100%;
}

.mod-chest .chest-close {
  width: 320px;
  height: 135px;
  opacity: 0;
  position: relative;
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 0px;
  margin-left: -160px;
}

.mod-chest .chest-close .gift {
  width: 320px;
  height: 135px;
  background: url(http://www.bncc.com/static/pc/img/unpacking/chest.png)
    no-repeat 0px 0px;
  background-size: 320px auto;
  position: absolute;
  left: 0;
  top: 0px;
}

.mod-chest .chest-close .gift_unpacking {
  background: url(http://www.bncc.com/static/pc/img/unpacking/chest_unpacking.png)
    no-repeat 0px 0px;
  background-size: 320px auto;
}

.mod-chest .chest-open {
  width: 320px;
  height: 150px;
  background: url(http://www.bncc.com/static/pc/img/unpacking/chest.png)
    no-repeat 0px -137px;
  background-size: 320px auto;
  opacity: 0;
  position: relative;
  z-index: 1;
  position: absolute;
  left: 0px;
  top: 0px;
}

.mod-chest .chest-open .mod-chest-cont {
  position: absolute;
  bottom: 44px;
  left: 50%;
  margin-left: -138px;
}

.mod-chest .show {
  z-index: 2;
  opacity: 1;
}
.wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.open-body {
  width: 100%;
  overflow: hidden;
}

.open-body .bgbody {
  position: absolute;
  z-index: 1;
  background: url(http://www.bncc.com/static/pc/img/unpacking/light.png) 0px
    0px no-repeat;
  background-size: 452px auto;
  width: 452px;
  height: 510px;
  max-height: 100%;
  left: 50%;
  margin-left: -226px;
  top: -25px;
}

.open-body .bgbody.rotate {
  -webkit-animation: rotate 10s infinite linear;
  -ms-animation: rotate 10s infinite linear;
  -moz-animation: rotate 10s infinite linear;
  -o-animation: rotate 10s infinite linear;
  animation: rotate 10s infinite linear;
}

.open-has {
  position: relative;
  z-index: 2;
  height: 450px;
  width: 320px;
  margin: 0px auto;
}

.open-has .title-close {
  opacity: 1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
  text-align: center;
}

.open-has .title-open {
  opacity: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  position: absolute;
  top: -60px;
  left: 0px;
  text-align: center;
  width: 100%;
  font-size: 16px;
}

.open-has h3 {
  color: #865500;
  /*font-size: 15px;*/
  font-size: 18px;
  text-align: center;
  padding-top: 75px;
  padding-bottom: 33px;
}

.open-has h3 .user {
  color: #ff5400;
  margin: 0px 5px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  font-size: 18px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.mod-chest .chest-close .tips {
  width: 166px;
  height: 60px;
  background: url(http://www.bncc.com/static/pc/img/unpacking/txt-tips-open.png)
    0px 0px no-repeat;
  background-size: 190px auto;
  position: absolute;
  left: 70px;
  top: 145px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.mod-chest .chest-close .tips_unpacking {
  width: 166px;
  height: 60px;
  background: url(http://www.bncc.com/static/pc/img/unpacking/txt-tips-open-unpackingdetail.png)
    0px 0px no-repeat;
  background-size: 190px auto;
  position: absolute;
  left: 70px;
  top: 145px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.mod-chest .chest-close .tips .arrow {
  width: 26px;
  height: 60px;
  background: url(http://www.bncc.com/static/pc/img/unpacking/txt-tips-open.png)
    right 0px no-repeat;
  background-size: 190px auto;
  position: absolute;
  display: block;
  top: 0px;
  right: -28px;
  -webkit-animation: move 0.7s linear infinite alternate;
  -ms-animation: move 0.7s linear infinite alternate;
  -moz-animation: move 0.7s linear infinite alternate;
  -o-animation: move 0.7s linear infinite alternate;
  animation: move 0.7s linear infinite alternate;
}

.mod-chest .chest-close:after {
  width: 320px;
  height: 300px;
  position: absolute;
  top: -100px;
  left: -61px;
  content: "";
  display: block;
}

.mod-chest .chest-close:active {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.mod-chest .chest-close.shakecopy .gift {
  -webkit-animation: shake 1.2s linear;
  -ms-animation: shake 1.2s linear;
  -moz-animation: shake 1.2s linear;
  -o-animation: shake 1.2s linear;
  animation: shake 1.2s linear;
  -webkit-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.mod-chest .chest-close.shakecopy .tips {
  opacity: 0;
}

@keyframes shake {
  0% {
    transform: scale(1);

    transform: scale3d(1, 1, 1);
  }

  6% {
    transform: scale(0.9) rotate(-8deg);

    transform: scale3d(1, 1, 1) rotate(0, 0, 1, -8deg);
  }

  18%,
  30%,
  42% {
    transform: scale(1.1) rotate(8deg);

    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 8deg);
  }

  12%,
  24%,
  36%,
  48% {
    transform: scale(1.1) rotate(-8deg);

    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -8deg);
  }

  54% {
    transform: scale(1);

    transform: scale3d(1, 1, 1);
  }

  60% {
    transform: scale(1);

    transform: scale3d(1, 1, 1);
  }

  80% {
    transform: scale(1) translate(0px, 150px);

    transform: scale3d(1, 1, 1) translate(0px, 150px);
  }

  90% {
    transform: scale(1) translate(0px, 130px);

    transform: scale3d(1, 1, 1) translate(0px, 130px);
  }

  100% {
    transform: scale(1) translate(0px, 150px);

    transform: scale3d(1, 1, 1) translate(0px, 150px);
  }
}

.opened {
  transform: translateY(150px);
  transition: transform 0.32s 0.6s;
}
</style>
