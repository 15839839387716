
import { defineComponent, nextTick, onMounted } from "vue";
import $ from "jquery";
export default defineComponent({
  name: "StartJob",
  setup() {
    const imgArr = [
      {
        img: require("../assets/img/start-job/pingguo.png"),
        width: "122px",
        top: "-67px",
        left: "-5px",
        text: "iphone12 Pro Max",
      },
      {
        img: require("../assets/img/start-job/kongtiao.png"),
        width: "30px",
        top: "-67px",
        left: "-5px",
        text: "美的(Midea)3匹客厅圆柱空调柜机",
      },
      {
        img: require("../assets/img/start-job/touyingyi.png"),
        width: "220px",
        top: "-49px",
        left: "24px",
        text: "小米投影仪",
      },
      {
        img: require("../assets/img/start-job/zixinche.png"),
        width: "170px",
        top: "-67px",
        left: "-5px",
        text: "麦瑞克家用动感单车",
      },
      {
        img: require("../assets/img/start-job/weibo.png"),
        width: "180px",
        top: "-78px",
        left: "0",
        text: "美的微波炉",
      },
      {
        img: "http://www.bncc.com/static/pc/img/unpacking/integral.png",
        width: "80px",
        top: "-49px",
        left: "24px",
        text: "积分18888",
      },
      {
        img: "http://www.bncc.com/static/pc/img/unpacking/integral.png",
        width: "80px",
        top: "-49px",
        left: "24px",
        text: "积分8888",
      },
      {
        img: require("../assets/img/start-job/guangpan.png"),
        width: "80px",
        top: "-49px",
        left: "24px",
        text: "北纳生物培训中心课程光盘",
      },
      {
        img: require("../assets/img/start-job/lipin.png"),
        width: "80px",
        top: "-49px",
        left: "24px",
        text: "伟业数据线",
      },
    ];
    let chestCloseTimer: number | undefined;
    onMounted(() => {
      nextTick(() => {
        $(".chest-onclose").click(function () {
          const that = $(".chest-onclose");
          const floorNum = Math.floor(Math.random() * 10);
          $(that).addClass("shakecopy");

          let imgUrl = {};
          for (let i = 0; i < imgArr.length; i++) {
            if (floorNum == i) {
              imgUrl = imgArr[i];
            }
          }
          $(".userText").text((imgUrl as any).text);
          $(".chest_zuan").css({
            width: (imgUrl as any).width,
            top: (imgUrl as any).top,
            left: (imgUrl as any).left,
          });
          $(".chest_zuan").attr("src", (imgUrl as any).img);
          $(".open-has").addClass("opened");
          setTimeout(function () {
            $(that).removeClass("show");
            $(".chest-open").addClass("show");
            setTimeout(function () {
              $(".chest-open").addClass("blur");
            }, 500);
          }, 700);
          // chestCloseTimer = setTimeout(function () {
          //   $(".chest-close").removeClass("shakecopy");
          //   $(".open-has").removeClass("opened");
          //   $(".chest-open").removeClass("show");
          //   $(".chest-close").addClass("show");
          //   $(".chest-open").removeClass("blur");
          // }, 4000);
        });

        $(".close-unpacking-img").click(function () {
          clearTimeout(chestCloseTimer);
          $(".chest-close").removeClass("shakecopy");
          $(".open-has").removeClass("opened");
          $(".chest-open").removeClass("show");
          $(".chest-close").addClass("show");
          $(".chest-open").removeClass("blur");
        });
      });
    });
  },
});
